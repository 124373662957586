import React from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { FormattedMessage } from "react-intl";
import apps from "../../apps";
import classNames from "classnames";

const {CORE, LUDO, POP_THE_BALL, TEEN_PATTI, GENIES_WONDER, GENIES, CLUB_RUMMY, POKER, SNAKE_AND_LADDERS, BINARY_OPTION, BONUS_SYSTEM, TEMBO_CLIENT } = apps;

function SwitchTo({user, className}) {

    const isEnabled = (id) => {
        return user.profile.IsSuperAdmin || user.profile.Apps.indexOf(id) > -1;
    }

    const menuArr = [
        {
            title: "",
            enabled: true,
            childrens: [
                {
                    lable: <FormattedMessage id="core" />,
                    enabled: isEnabled(CORE.id),
                    ...CORE
                }
            ]
        },
        {
            title: <FormattedMessage id="game_s" />,
            enabled: true,
            childrens: [
                {
                    lable: <FormattedMessage id="ludo" />,
                    enabled: isEnabled(LUDO.id),
                    ...LUDO
                },
                {
                    lable: <FormattedMessage id="club_rummy" />,
                    enabled: isEnabled(CLUB_RUMMY.id),
                    ...CLUB_RUMMY
                },
                {
                    lable: <FormattedMessage id="pop_the_ball" />,
                    enabled: isEnabled(POP_THE_BALL.id),
                    ...POP_THE_BALL
                },
                {
                    lable: <FormattedMessage id="poker" />,
                    enabled: isEnabled(POKER.id),
                    ...POKER
                },
                {
                    lable: <FormattedMessage id="genies" />,
                    enabled: isEnabled(GENIES.id),
                    ...GENIES
                },
                {
                    lable: <FormattedMessage id="genies_wonder" />,
                    enabled: isEnabled(GENIES_WONDER.id),
                    ...GENIES_WONDER
                },
                {
                    lable: <FormattedMessage id="snakes_and_ladders" />,
                    enabled: isEnabled(SNAKE_AND_LADDERS.id),
                    ...SNAKE_AND_LADDERS
                }
            ]
        },
        {
            title: <FormattedMessage id="service_s" />,
            enabled: true,
            childrens: [
                {
                    lable: <FormattedMessage id="binary_option" />,
                    enabled: isEnabled(BINARY_OPTION.id),
                    ...BINARY_OPTION
                },
                
                {
                    lable: <FormattedMessage id="bonus_system" />,
                    enabled: isEnabled(BONUS_SYSTEM.id),
                    ...BONUS_SYSTEM
                },
                {
                    lable: <FormattedMessage id="tembo" />,
                    enabled: isEnabled(TEMBO_CLIENT.id),
                    ...TEMBO_CLIENT
                }
            ]
        }
    ]
    
    menuArr.forEach(item => {
        let obj = item.enabled && item.childrens.length && item.childrens.reduce((a, b) => a === true || a.enabled || b.enabled);
        item.enabled = typeof obj === "object" ? obj.enabled : obj || false;
    })
    

    return (
        <div className={classNames("switch-to", className)}>
            <h2 className="text-capitalize"><FormattedMessage id="switch_to_" /></h2>
            {menuArr.map((item, index) => {
                if (item.enabled) {
                    return (
                        <div key={index} className="block">
                        {item.title && <span className="title">{item.title}</span> || ''}
                        <Menu className='menu-block'>
                            {
                                item.childrens.map((child, i) => {
                                    if (child.enabled) {
                                        return (
                                            <Menu.Item key={i}>
                                                <a rel="noopener noreferrer" href={child.url}>
                                                    <img src={child.icon} />
                                                    {child.lable}
                                                </a>
                                            </Menu.Item>
                                        )
                                    } else {
                                        return "";
                                    }
                                })
                            }
                        </Menu>
                    </div> )
                } else {
                    return "";
                }
            })}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchTo);