import apps from "../apps";

const {TEEN_PATTI} = apps;

export function isHavePermissionThisApp(user) {
    try {
        if (user.profile.IsSuperAdmin) return true;
        const apps = user.profile.Apps.split(',');
        if (apps.indexOf(TEEN_PATTI.id) > -1) {
            return true;
        }
    } catch(e) {
        return false;
    }
    return false
}

export function getTheAppsUrlToByRedirected(user) {
    try {
        const userApps = user.profile.Apps.split(',');
        try {
            Object.keys(apps).forEach((appName) => {
                console.log(userApps.indexOf(apps[appName].id))
                if (userApps.indexOf(apps[appName].id) > -1) {
                    throw apps[appName].url;
                }
            })
        } catch (url) {
            return url;
        }
    } catch (e) {
        return ""
    }
    
    return "";
}