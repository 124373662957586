export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: null,
    emeptyObj: {},
    emptyArr: [],
    playersList: {
        list: [],
        listModalVisible: false,
    },
    allowCancel: {
        allowBets: false,
        allowTransaction: false
    }
}