import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {getUrlValue} from "../../../utils/CommonUtils";
import {Table} from "antd";
import {getActiveBetList, clearActiveBetList} from "@actions/activeBetsAction";
import ActiveBetsFilter from "@components/ActiveBetsFilter/ActiveBetsFilter";
import TableLoader from "@components/Loaders/tableLoader";

function ActiveBets({intl, loading, getActiveBetList, clearActiveBetList, activeBets}) {
    let history = useHistory()
    const [online, setOnline] = useState(navigator.onLine);
    const timeOut = useRef([]);

    useEffect(() => {
        function enable(e) { setOnline(true) }
        function disable(e) { setOnline(false) }
        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
            clearActiveBetList();
        }
    },[])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            fetchBets()
        }
    },[online])

    const columns = [
        {
            title: intl.formatMessage({id: "client"}),
            dataIndex: 'client',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.client}</div>
            }
        },
        {
            title: intl.formatMessage({id: "player"}),
            dataIndex: 'playerName',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.player}</div>
            }
        },
        {
            title: intl.formatMessage({id: "amount"}),
            dataIndex: 'amount',
            width: "fit-content",
            render: (text, record) => {
                return <div>{record.amount}</div>
            }
        }
    ];

    const getBetsRequestBody = (loc) => {
        let search = history && history.location && history.location.search
        return getUrlValue( loc || search);
    }

    const fetchBets = () => {
        const initialForm = getBetsRequestBody()
        getActiveBetList(initialForm)
    }

    useEffect(() => {
        if (!loading._active_bet_list && online) {
            timeOut.current.push(setInterval(() => {fetchBets()}, 5000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._active_bet_list])

    useEffect(() => {
        return history.listen((location, action) => {
            const loc = location.search
            if (loc) {
                const initialForm = getBetsRequestBody(loc)
                getActiveBetList(initialForm)
                timeOut.current.forEach(time => clearInterval(time))
                timeOut.current = []
            }
        })
    },[history])



    return (
        <div className="content bets">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='active_bets'/></div>
            </div>
            <ActiveBetsFilter />
            <div className="bets">
                <div className="mainTable">
                    {loading._active_bet_list && !activeBets.items ? <TableLoader count={20} column={3} drag={false} icon={false} actions={0} pagination={false}/> :
                        <div>
                            <Table dataSource={activeBets.items || []}
                                   columns={columns}
                                   scroll={{x: '100%'}}
                                   rowKey="createdDate"
                                   pagination={false}
                            />
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        activeBets: state.activeBetsList,
        loading: state.loading,
    }
}

const mapDispatchToProps = {
    getActiveBetList,
    clearActiveBetList
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActiveBets));