import React, {useEffect, useContext} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient
} from "@actions/clientsActions";
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";


import {Modal, Form, Input, Row, Col, Switch, Select, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import {GameType} from "@constants/Enums";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import iconDown from "@assets/img/icon/iconDown.svg";

function ClientControlPopup({handleCancel, show, intl, addClient, client, editClient, loading}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        if (client.id) {
            const data = {...client, availableModes: client.config.availableModes || [] ,  ...client.config}
            data.availableModes =  client.config.availableModes || []
            form.setFieldsValue(data)
        }
     }, [client])

    const onFinish = (data) => {
        const body ={config:{}}
        Object.keys(data).forEach(item => {
            if(item === "onlyBots" || item === "isUnderMaintenance" || item === "availableModes") {
                body["config"][item] = data[item]
            }
            else {
                body[item] = data[item]
            }
        })
        if (show === "add") {
            addClient(body)
        } else {
            editClient({...body, id: client.id, name: client.name})
        }
    }

    useEffect(() => {
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            });
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_client'/> :
                        <FormattedMessage id='edit_client'/>}
                    centered
                    className={`formModal`}
                    visible={show}
                    width={448}
                    onCancel={() => handleCancel()}
                    footer={false}
                   >
                    <Form
                        layout="vertical"
                        form={form}
                        name="trading-form"
                        onFinish={onFinish}
                        scrollToFirstError
                        validateTrigger="onBlur"
                    >
                        <Row className="MainModal">
                            <Col span={24} className="MainModal--parts">
                                {show === "add" ?
                                    <Form.Item label={<FormattedMessage id='name'/>}
                                               rules={[
                                                   {
                                                       required: show === "add",
                                                       message: <FormattedMessage id='name_validator'/>
                                                   },
                                                   ({getFieldValue}) => ({
                                                       validator(_, value) {

                                                           if (value && !(value.length >= 3 && value.length <= 256))
                                                           {
                                                               return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                           }

                                                           return Promise.resolve();
                                                       },
                                                   })
                                               ]}
                                               name={"name"}>
                                        <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_name"})}/>
                                    </Form.Item>:
                                    <Form.Item label={<FormattedMessage id='name'/>}>
                                        {client.name}
                                    </Form.Item>
                                }
                                <Form.Item label={<FormattedMessage id='secret'/>}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: <FormattedMessage id='secret_validator'/>
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {

                                                       if (value && value.length > 255)
                                                       {
                                                           return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))

                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name={"secret"}
                                >
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_secret"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='bet_url'/>}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: <FormattedMessage id='bet_url_validator'/>
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                       if (value && !urlPattern.test(value)) {
                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name={"betCallback"}
                                >
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_bet_url"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='result_url'/>}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: <FormattedMessage id='result_url_validator'/>
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                       if (value && !urlPattern.test(value)) {
                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name={"resultCallback"}
                                >
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_result_url"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id='balance_url'/>}
                                           rules={[
                                               {
                                                   required: true,
                                                   message: <FormattedMessage id='balance_url_validator'/>
                                               },
                                               ({getFieldValue}) => ({
                                                   validator(_, value) {
                                                       const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                       if (value && !urlPattern.test(value)) {
                                                           return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                       }
                                                       return Promise.resolve();
                                                   },
                                               })
                                           ]}
                                           name={"balanceCallback"}
                                >
                                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_balance_url"})}/>
                                </Form.Item>
                                <Form.Item label={<FormattedMessage id={"available_modes"}/>}
                                           name="availableModes"
                                           rules={[
                                               {
                                                   required: true,
                                                   message: <FormattedMessage id='available_modes_validator'/>
                                               }]}>
                                    <Select mode="multiple"
                                            getPopupContainer={triggerNode => triggerNode.parentElement}
                                            optionLabelProp="label"
                                            showArrow
                                            showSearch={false}
                                            suffixIcon={<img src={iconDown} />}>
                                        {
                                            Object.keys(GameType).map(key =>
                                                <Select.Option key={+key} value={+key} label={<FormattedMessage id={GameType[+key]}/>}>
                                                    <FormattedMessage id={GameType[+key]}/>
                                                </Select.Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id='only_bots'/>}
                                    className="formHorizontal"
                                    name="onlyBots"
                                    valuePropName="checked">
                                    <Switch className="radioSwitch"/>
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id='is_under_maintenance'/>}
                                    className="formHorizontal"
                                    name="isUnderMaintenance"
                                    valuePropName="checked">
                                    <Switch className="radioSwitch"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className="miniBtn"
                                    loading={loading._add_client || loading._edit_client}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = {
    addClient,
    editClient
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));